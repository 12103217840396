import React from "react";
import styled from "styled-components";

function GlebOrbUnstyled() {
  return (
    <Container>
      <OrbContainer>
        <GlebOrb />
      </OrbContainer>
    </Container>
  );
}

function GlebOrb() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1217 778">
      <path
        fill="url(#paint0_linear_2)"
        fillOpacity="0.25"
        d="M1039.56 820.557c-74.398 74.396-155.817 113.6-181.857 87.561-26.036-26.04 13.165-107.459 87.559-181.855 74.398-74.393 155.818-113.597 181.858-87.558 26.03 26.039-13.17 107.458-87.56 181.852z"
      ></path>
      <path
        fill="url(#paint1_linear_2)"
        fillOpacity="0.25"
        d="M1036.86 817.862C927.502 927.226 807.485 984.52 768.799 945.834c-38.685-38.686 18.609-158.702 127.972-268.065C1006.13 568.408 1126.15 511.115 1164.83 549.8c38.69 38.686-18.6 158.702-127.96 268.062h-.01z"
      ></path>
      <path
        fill="url(#paint2_linear_2)"
        fillOpacity="0.25"
        d="M1026.08 807.088C889.197 943.975 738.423 1015.14 689.322 966.04c-49.104-49.104 22.062-199.875 158.952-336.762 136.888-136.887 287.666-208.056 336.766-158.955 49.1 49.104-22.07 199.878-158.96 336.765z"
      ></path>
      <path
        fill="url(#paint3_linear_2)"
        fillOpacity="0.25"
        d="M1007.23 788.229C845.042 950.412 667.131 1035.45 609.846 978.161c-57.285-57.281 27.752-235.195 189.935-397.378 162.183-162.185 340.099-247.219 397.379-189.935 57.29 57.285-27.75 235.199-189.93 397.381z"
      ></path>
      <path
        fill="url(#paint4_linear_2)"
        fillOpacity="0.25"
        d="M982.981 763.982C800.708 946.251 601.086 1042.15 537.105 978.161c-63.979-63.978 31.913-263.603 214.182-445.872 182.27-182.272 381.893-278.163 445.873-214.182 63.98 63.978-31.91 263.606-214.182 445.875h.003z"
      ></path>
      <path
        fill="url(#paint5_linear_2)"
        fillOpacity="0.25"
        d="M957.388 738.389C758.75 937.026 540.73 1041.06 470.427 970.755c-70.303-70.306 33.73-288.322 232.367-486.959 198.638-198.637 416.656-302.67 486.966-232.367 70.3 70.303-33.73 288.322-232.372 486.96z"
      ></path>
      <path
        fill="url(#paint6_linear_2)"
        fillOpacity="0.25"
        d="M927.75 708.752C714.979 921.523 481.578 1033.1 406.441 957.959c-75.14-75.14 36.433-308.54 249.205-521.311 212.771-212.771 446.174-324.344 521.304-249.207 75.15 75.143-36.43 308.54-249.2 521.311z"
      ></path>
      <path
        fill="url(#paint7_linear_2)"
        fillOpacity="0.25"
        d="M891.379 672.382C668.196 895.568 423.337 1012.57 344.476 933.708c-78.859-78.858 38.143-323.716 261.329-546.902C828.992 163.62 1073.85 46.619 1152.71 125.477c78.86 78.862-38.14 323.716-261.328 546.905h-.003z"
      ></path>
      <path
        fill="url(#paint8_linear_2)"
        fillOpacity="0.25"
        d="M852.319 633.316C620.946 864.689 367.346 986.211 285.881 904.749c-81.462-81.465 40.06-335.067 271.431-566.437 231.37-231.37 484.978-352.895 566.438-271.43 81.46 81.462-40.06 335.064-271.431 566.434z"
      ></path>
      <path
        fill="url(#paint9_linear_2)"
        fillOpacity="0.25"
        d="M805.172 586.169c-236.58 236.579-494.706 362.024-576.543 280.188-81.834-81.834 43.611-339.959 280.188-576.535C745.397 53.239 1003.52-72.205 1085.36 9.628c81.83 81.837-43.61 339.962-280.188 576.538v.003z"
      ></path>
      <path
        fill="url(#paint10_linear_2)"
        fillOpacity="0.25"
        d="M758.025 536.331C519.958 774.395 260.624 901.046 178.791 819.21c-81.837-81.834 44.815-341.166 282.879-579.233C699.737 1.914 959.07-124.738 1040.9-42.901c81.84 81.833-44.811 341.166-282.875 579.232z"
      ></path>
      <path
        fill="url(#paint11_linear_2)"
        fillOpacity="0.25"
        d="M708.181 489.181c-236.58 236.582-494.706 362.026-576.54 280.193-81.837-81.84 43.608-339.965 280.188-576.544C648.406-43.746 906.532-169.191 988.366-87.355c81.844 81.834-43.609 339.959-280.185 576.536z"
      ></path>
      <path
        fill="url(#paint12_linear_2)"
        fillOpacity="0.25"
        d="M659.688 440.686C428.317 672.062 174.714 793.584 93.249 712.122c-81.462-81.465 40.061-335.064 271.434-566.437 231.371-231.37 484.974-352.892 566.436-271.427 81.461 81.462-40.061 335.064-271.431 566.434v-.006z"
      ></path>
      <path
        fill="url(#paint13_linear_2)"
        fillOpacity="0.25"
        d="M611.193 392.192C388.006 615.384 143.145 732.383 64.289 653.528c-78.862-78.862 38.14-323.72 261.327-546.906 223.193-223.189 468.045-340.19 546.907-261.332 78.858 78.862-38.144 323.719-261.33 546.905v-.003z"
      ></path>
      <path
        fill="url(#paint14_linear_2)"
        fillOpacity="0.25"
        d="M562.7 343.698C349.928 556.472 116.528 668.045 41.39 592.905c-75.14-75.14 36.434-308.537 249.205-521.308C503.367-141.176 736.767-252.747 811.904-177.61c75.141 75.141-36.433 308.54-249.204 521.311v-.003z"
      ></path>
      <path
        fill="url(#paint15_linear_2)"
        fillOpacity="0.25"
        d="M514.205 295.206C315.567 493.844 97.55 597.876 27.245 527.573-43.06 457.27 60.973 239.251 259.611 40.613c198.637-198.637 416.657-302.669 486.96-232.366 70.303 70.303-33.729 288.322-232.367 486.959z"
      ></path>
      <path
        fill="url(#paint16_linear_2)"
        fillOpacity="0.25"
        d="M465.711 246.712C283.441 428.985 83.816 524.876 19.837 460.895c-63.98-63.979 31.913-263.606 214.18-445.875C416.29-167.25 615.912-263.144 679.893-199.163c63.979 63.982-31.913 263.606-214.182 445.875z"
      ></path>
      <path
        fill="url(#paint17_linear_2)"
        fillOpacity="0.25"
        d="M417.215 198.22C255.032 360.405 77.121 445.439 19.836 388.155-37.449 330.87 47.586 152.956 209.77-9.226c162.186-162.183 340.1-247.22 397.382-189.935 57.285 57.284-27.752 235.198-189.936 397.381z"
      ></path>
      <path
        fill="url(#paint18_linear_2)"
        fillOpacity="0.25"
        d="M368.723 149.724C231.836 286.611 81.061 357.779 31.961 308.679c-49.104-49.104 22.064-199.878 158.955-336.765 136.887-136.887 287.659-208.053 336.762-158.955 49.101 49.104-22.067 199.878-158.955 336.765z"
      ></path>
      <path
        fill="url(#paint19_linear_2)"
        fillOpacity="0.25"
        d="M320.231 101.231C210.867 210.594 90.851 267.888 52.165 229.202 13.479 190.517 70.773 70.501 180.137-38.863 289.497-148.223 409.514-205.517 448.2-166.83c38.685 38.685-18.609 158.702-127.969 268.062z"
      ></path>
      <path
        fill="url(#paint20_linear_2)"
        fillOpacity="0.25"
        d="M271.735 52.739c-74.397 74.393-155.816 113.594-181.855 87.558-26.036-26.04 13.164-107.459 87.561-181.852 74.393-74.396 155.816-113.6 181.852-87.561 26.039 26.039-13.165 107.459-87.558 181.855z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint19_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint20_linear_2"
          x1="0"
          x2="1217"
          y1="998"
          y2="998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="0.201" stopColor="#9EBDFF"></stop>
          <stop offset="0.397" stopColor="#D1A5F6"></stop>
          <stop offset="0.602" stopColor="#FC89CB"></stop>
          <stop offset="0.798" stopColor="#FF788D"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default GlebOrbUnstyled;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  height: 100%;
`;

const OrbContainer = styled.div`
  width: 100%;
  height: 100%;

  svg {
    height: 100%;
    overflow: visible;
    transform: scale(0.85);
    transform-origin: center center !important;
  }
`;
