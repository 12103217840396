import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PageProps } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import SEO from "@components/SEO";
import GlebOrbUnstyled from "@components/DecorativeBackground/GlebOrbUnstyled";
import Stack from "@components/Stack";
import FileIcons from "@components/FileRows/FileIcons";
import { summarySteps } from "@utils/steps";
import timeAgo from "@utils/timeago";
import { formatBytes } from "@utils";
import { File as FileImage } from "@styled-icons/fa-solid/File";
import { useNProgress } from "@tanem/react-nprogress";

interface FormEntry {
  brief_form_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  value: string;
}
interface FormField {
  brief_id: number;
  created_at: string;
  entries: Array<FormEntry>;
  id: number;
  name: string;
  updated_at: string;
}

interface FormFile {
  brief_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  url: string;
  name: string;
  size: number;
  mime: string;
}

interface Brief {
  name: string;
  email: string;
  organization: string;
  briefName: string;
  forms: {
    [formName: string]: Array<string>;
  };
  files: Array<FormFile>;
  created_at: Date;
}

interface Organization {
  api_url: string;
  approved: number;
  color: string;
  created_at: string;
  icon: string;
  id: number;
  name: string;
  transcriptionsEnabled: number;
  updated_at: string;
}

interface BriefResponse {
  status: string;
  response: {
    briefName: string;
    created_at: string;
    email: string;
    files: Array<FormFile>;
    forms: Array<FormField>;
    hash: string;
    id: number;
    name: string;
    organization: Organization;
    organization_id: number;
    updated_at: string;
  };
}

function getFormFields(formFields: Array<FormField>) {
  const result = {} as {
    [formName: string]: Array<string>;
  };

  for (const formField of formFields) {
    result[formField.name] = formField.entries.map((entry) => entry.value);
  }

  return result;
}

function getHashCodeFromURL(queryString: string) {
  const searchParams = new URLSearchParams(queryString);
  const hash = searchParams.get("h");

  return hash;
}

function BriefView({ location }: PageProps) {
  const [brief, setBrief] = useState<Brief | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isLoading
  });

  const hash = getHashCodeFromURL(location.search);

  useEffect(() => {
    if (hash === null) {
      setIsErrored(true);
      setIsLoading(false);
    }
  }, [hash]);

  useEffect(() => {
    async function loadData() {
      try {
        const url = `https://dash-api.sympler.co/api/v1/brief?hash=${hash}`;
        const rawResponse = await fetch(url);
        const response: BriefResponse = await rawResponse.json();

        if (response.status === "failed") {
          throw new Error("Permission denied");
        }

        const {
          name,
          email,
          organization,
          briefName,
          forms,
          files,
          created_at
        } = response.response;

        const date = new Date(created_at.replace(" ", "T"));

        date.setHours(date.getHours() - 4);

        const localBrief: Brief = {
          name,
          email,
          briefName,
          organization: organization.name,
          forms: getFormFields(forms),
          files,
          created_at: date
        };

        setBrief(localBrief);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setIsErrored(true);
      }
    }

    loadData();
  }, [hash]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isFinished === true && isErrored === false) {
        setShowContent(true);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isFinished, isErrored]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isErrored === true) {
        toast.error("Permission denied.");
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [isErrored]);

  return (
    <BriefWrapper>
      <SEO
        title={brief?.briefName ?? "Brief Summary"}
        pathname={location.pathname}
      />

      <GlebOrbUnstyled />

      <AnimatePresence exitBeforeEnter>
        {!showContent ? (
          <ContentContainer
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Stack
              type="column"
              gap={2}
              center
              style={{
                zIndex: 1,
                background: "hsla(0, 100%, 100%, 0.15)",
                borderRadius: "8px",
                padding: "1rem"
              }}
              stretchColumns
            >
              <ProgressBarContainer>
                <ProgressBar
                  style={{
                    background: `${isErrored ? "tomato" : "#635ec0"}`,
                    marginLeft: `${(-1 + progress) * 100}%`,
                    transition: `margin-left ${animationDuration}ms linear, background 300ms ease`
                  }}
                >
                  <ProgressBarShadow
                    style={{
                      boxShadow: `0 0 10px ${
                        isErrored ? "tomato" : "#635ec0"
                      }, 0 0 5px ${isErrored ? "tomato" : "#635ec0"}`
                    }}
                  />
                </ProgressBar>
              </ProgressBarContainer>
            </Stack>
          </ContentContainer>
        ) : (
          <ContentContainer
            key="not-loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <SummaryInterfaceContainer type="column" gap={2} stretchColumns>
              {brief?.created_at && (
                <CredentialGroupTime>
                  Submitted {timeAgo.format(brief?.created_at)}
                </CredentialGroupTime>
              )}

              <CredentialGroup type="column" gap={0} stretchColumns>
                <StepGroup type="row" gap={2}>
                  <Step>1</Step>

                  <StepAnswered>Respondent Information</StepAnswered>
                </StepGroup>

                <Stack type="column" gap={1} stretchColumns>
                  <TwoColumnGrouping type="row" gap={1}>
                    <ResponseGrouping
                      type="column"
                      gap={0.5}
                      stretchColumns
                      isMissingResponse={brief?.name.length === 0}
                    >
                      <ResponseQuestion>Name</ResponseQuestion>

                      <QuestionResponse>{brief?.name}</QuestionResponse>
                    </ResponseGrouping>
                    <ResponseGrouping
                      type="column"
                      gap={0.5}
                      stretchColumns
                      isMissingResponse={brief?.email.length === 0}
                    >
                      <ResponseQuestion>Email</ResponseQuestion>

                      <QuestionResponse>{brief?.email}</QuestionResponse>
                    </ResponseGrouping>
                  </TwoColumnGrouping>

                  <TwoColumnGrouping type="row" gap={1}>
                    <ResponseGrouping
                      type="column"
                      gap={0.5}
                      stretchColumns
                      isMissingResponse={brief?.organization.length === 0}
                    >
                      <ResponseQuestion>Company</ResponseQuestion>

                      <QuestionResponse>{brief?.organization}</QuestionResponse>
                    </ResponseGrouping>

                    <ResponseGrouping
                      type="column"
                      gap={0.5}
                      stretchColumns
                      isMissingResponse={brief?.briefName.length === 0}
                    >
                      <ResponseQuestion>Brief Name</ResponseQuestion>

                      <QuestionResponse>{brief?.briefName}</QuestionResponse>
                    </ResponseGrouping>
                  </TwoColumnGrouping>
                </Stack>
              </CredentialGroup>

              <CredentialGroup type="column" gap={1} stretchColumns>
                <StepGroup type="row" gap={2}>
                  <Step>2</Step>

                  <StepAnswered>Brief Information</StepAnswered>
                </StepGroup>

                {summarySteps.map(({ questions }) => {
                  return questions.map(
                    ({ question, questionNumber, formName }) => {
                      const response: Array<string> =
                        brief?.forms[formName] ?? [];
                      const isMissingResponse =
                        response.filter((text) => text !== "").length === 0;

                      return (
                        <ResponseGrouping
                          type="column"
                          gap={0.5}
                          stretchColumns
                          key={questionNumber}
                          isMissingResponse={isMissingResponse}
                        >
                          <ResponseQuestion style={{ textAlign: "left" }}>
                            {question}
                          </ResponseQuestion>

                          <QuestionResponse
                            as={"ul"}
                            type="column"
                            gap={1}
                            style={{
                              textAlign: "left",
                              fontStyle: isMissingResponse ? "italic" : "none",
                              color: isMissingResponse ? "#928ED3" : "#132142"
                            }}
                          >
                            {isMissingResponse
                              ? "No response"
                              : response
                                  .filter((text) => text !== "")
                                  .map((text, index) => (
                                    <li
                                      key={`${text}-${index}`}
                                      style={{ listStyle: "inside" }}
                                    >
                                      {text}
                                    </li>
                                  ))}
                          </QuestionResponse>
                        </ResponseGrouping>
                      );
                    }
                  );
                })}
              </CredentialGroup>

              <CredentialGroup type="column" gap={0} stretchColumns>
                <StepGroup type="row" gap={2}>
                  <Step>3</Step>

                  <StepAnswered>Attachments</StepAnswered>
                </StepGroup>

                <ResponseGroupingBasic type="column" gap={0} stretchColumns>
                  <SummaryContainer type="column" gap={1} stretchColumns>
                    <FileHeader>
                      {brief?.files.length} file
                      {brief?.files.length === 1 ? "" : "s"} attached
                    </FileHeader>

                    <FileRowContainer type="column" gap={0} stretchColumns>
                      {brief?.files.map((file) => {
                        return (
                          <FileRow
                            key={file.name}
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {FileIcons[file.mime]}

                            <FileName>{file.name}</FileName>

                            <FileSize>{formatBytes(file.size)}</FileSize>

                            <FileRemove>View</FileRemove>
                          </FileRow>
                        );
                      })}

                      {brief?.files.length === 0 && (
                        <FileRow>
                          <FileIcon />
                          No materials attached.
                        </FileRow>
                      )}
                    </FileRowContainer>
                  </SummaryContainer>
                </ResponseGroupingBasic>
              </CredentialGroup>
            </SummaryInterfaceContainer>
          </ContentContainer>
        )}
      </AnimatePresence>

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "14.5px",
            letterSpacing: "0.25px",
            color: "#142132",
            margin: "50px"
          },
          success: {
            iconTheme: {
              primary: "#635ec0",
              secondary: "#ffffff"
            }
          }
        }}
      />
    </BriefWrapper>
  );
}

export default BriefView;

const BriefWrapper = styled.main`
  width: 100%;
  height: 100%;
  max-width: 100vw;
  min-height: 100vh;
  display: grid;
  place-content: center;
  grid-template-columns: minmax(0, 1fr);
  position: relative;
  background: linear-gradient(
    138.24deg,
    rgba(219, 231, 240, 0.26) 4.35%,
    rgba(159, 167, 238, 0.36) 94.6%
  );
  padding: 0 10%;
  /* overflow: hidden; */

  @media (max-width: 950px) {
    padding: 0 2%;
  }
`;

const ContentContainer = styled(motion.div)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem 0;
`;

const SummaryInterfaceContainer = styled(Stack)`
  --purple-300: #8c88d1;
  --purple-500: #635ec0;
  --purple-700: #4540a4;

  width: clamp(600px, 65vw, 690px);
  margin: 0 auto;
  overflow: visible;
  align-self: center;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const CredentialGroup = styled(Stack)`
  z-index: 2;
  isolation: isolate;
  /* overflow: hidden; */
  border-radius: 2rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.35);
`;

const CredentialGroupTime = styled.div`
  background: #635ec0;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-self: center;
`;

const StepGroup = styled(Stack)`
  padding: 3rem 2rem;
`;

const Step = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  background: rgba(99, 94, 192, 0.9);
  color: white;
  mix-blend-mode: multiply;
  border-radius: 9999px;
  font-weight: bold;
  width: 4.1rem;
  height: 4.1rem;
  position: relative;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  transition: background 300ms ease, color 300ms ease;
`;

const StepAnswered = styled.div`
  /* text-transform: uppercase; */
  font-size: 2.4rem;
  font-weight: 200;
  text-align: left;
  position: relative;
  font-size: clamp(2.8rem, 5vw, 3.1rem);
  font-weight: 200;
  color: #132142;
  /* color: #4b45b1; */
  line-height: 1;
  display: flex;
  align-items: center;

  :hover {
    background: none;
  }
`;

const TwoColumnGrouping = styled(Stack)`
  grid-template-columns: 1fr 1fr;
  color: inherit;

  @media (max-width: 950px) {
    grid-template-columns: minmax(0, 1fr);
    grid-auto-flow: row;
  }
`;

const ResponseGroupingBasic = styled(Stack)`
  padding: 2rem;
  color: #635ec0;
  transition: background 300ms ease;
  align-self: stretch;
  border-radius: 1rem;
`;

const ResponseQuestion = styled.p`
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #635ec0;

  span {
    font-size: 1.4rem;
    font-family: "Catamaran", sans-serif;
    color: tomato;
  }
`;

const QuestionResponse = styled(Stack)`
  white-space: pre-line;
  text-align: left;
  color: #132142;
`;

const FileRowContainer = styled(Stack)`
  border-radius: 8px;
  overflow: hidden;

  > a:nth-child(odd) {
    background: rgba(255, 255, 255, 0.15);
    transition: background 300ms ease;

    :hover {
      background: rgba(255, 255, 255, 0.65);
    }
  }

  > a:nth-child(even) {
    background: rgba(255, 255, 255, 0.3);
    transition: background 300ms ease;

    :hover {
      background: rgba(255, 255, 255, 0.65);
    }
  }
`;

const FileRow = styled.a`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  mix-blend-mode: multiply;
  cursor: pointer;
`;

const FileIcon = styled(FileImage)`
  display: inline-block;
  height: 22px;
  margin-right: 2ch;
  color: #635ec0;
`;

const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const FileName = styled.span`
  font-size: 1.55rem;
  color: #132142;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileSize = styled.span`
  margin: 0 2ch;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #3f3a95;
  align-self: center;
  white-space: nowrap;
  flex-grow: 1;
  text-align: left;
`;

const FileRemove = styled.span`
  color: #2d2a6b;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1rem;
  text-decoration: none;
  flex-shrink: 0;

  :hover {
    text-decoration: underline;
  }
`;

const SummaryContainer = styled(Stack)`
  /* background: #deddf2; */
  border-radius: 8px;
`;

const FileHeader = styled.div`
  text-transform: uppercase;
  font-size: 14.5px;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: #635ec0;
  text-align: left;

  span {
    font-weight: 400;
  }
`;

const ProgressBarContainer = styled.div`
  pointer-events: none;
  overflow: hidden;
  width: 50vw;
  border-radius: 9999px;
`;

const ProgressBar = styled.div`
  position: relative;
  height: 4px;
  width: 100%;
`;

const ProgressBarShadow = styled.div`
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: rotate(3deg) translate(0px, -4px);
  transition: background 300ms ease;
  width: 100px;
`;

const ResponseGrouping = styled(ResponseGroupingBasic)<{
  isMissingResponse: boolean;
}>`
  &:hover {
    background: rgba(255, 255, 255, 0.3);
    color: #4540a4;
  }

  ${(props) =>
    props.isMissingResponse &&
    `
      background: rgba(255, 99, 71, 0.15);


      :hover {
        background:  rgba(255, 99, 71, 0.3);
      }
    `}
`;
